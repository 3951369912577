<template>
  <div>
    <b-alert v-if="errored" variant="danger" show>
      <p>{{ _entity.message }}</p>
      <p>
        Please note that some servers don't allow external access via web browsers (e.g., when CORS headers are not
        present).
      </p>
      <p>Errored URL: {{ url }}</p>
      <p><a href="#" @click="$router.go(-1)">Go back</a></p>
    </b-alert>
    <b-spinner v-else-if="!loaded" label="Loading..."></b-spinner>
    <div v-else :class="loaded && 'loaded'">
      <NavBar></NavBar>
      <b-breadcrumb :items="breadcrumbs" />

      <b-container>
        <b-card-group deck>
          <b-card title="Catalog">
            <b-card-text>Collection of public and private remote sensing data. Browse here or use our clients.</b-card-text>
            <template #footer>
              <b-button
                href="https://git.dev-kayrros.ovh/geospatial/phx/blob/main/docs/index.md"
                variant="outline-light"
                >Python Client</b-button
              >
              <b-button
                href="https://git.dev-kayrros.ovh/geospatial/catalog/blob/main/api/openapi_v2.yaml"
                variant="outline-light"
                >Rest API</b-button
              >
              <b-button href="slack://channel?team=T0KRBK945&id=C01CJ4S8F46" variant="outline-light">Support</b-button>
            </template>
          </b-card>
          <b-card title="Mappamundi">
            <b-card-text>The tile server for remote sensing observations. </b-card-text>
            <template #footer>
              <b-button href="https://eu-west-2.prd-mappamundi.kayrros.org/" variant="outline-light"
                >Playground</b-button
              >
              <b-button
                href="https://git.dev-kayrros.ovh/geospatial/mappamundi/blob/main/api/openapi_v1.yml"
                variant="outline-light"
                >Rest API</b-button
              >
              <b-button href="slack://channel?team=T0KRBK945&id=C02C3GRTF0D" variant="outline-light">Support</b-button>
            </template>
          </b-card>
        </b-card-group>
        <b-row>
          <b-col :md="keywords.length > 0 ? 8 : 12">
            <div v-if="description" v-html="description" />
            <b-tabs :value="tabIndex" @input="selectTab" @activate-tab="activateTab">
              <b-tab :disabled="!this.childCount" key="catalogs" title="Collections">
                <b-table
                  :items="children"
                  :fields="childFields"
                  :per-page="childrenPerPage"
                  :current-page="currentChildPage"
                  :sort-compare="sortCompare"
                  :outlined="true"
                  responsive
                  small
                  striped
                >
                  <template slot="cell(link)" slot-scope="data">
                    <router-link :to="data.item.to">{{ data.item.title }}</router-link>
                  </template>
                  <template slot="cell(keywords)" slot-scope="data">
                    <b-badge v-for="k of data.item.keywords" variant="light" class="pr-2" :key="k">{{ k }}</b-badge>
                  </template>
                  <template slot="cell(constellation)" slot-scope="data">
                    <b-badge variant="light" class="pr-2">{{ data.item.constellation }}</b-badge>
                  </template>
                </b-table>
                <b-pagination
                  v-if="childCount > childrenPerPage"
                  v-model="currentChildPage"
                  :limit="15"
                  :total-rows="childCount"
                  :per-page="childrenPerPage"
                  :hide-goto-end-buttons="true"
                />
              </b-tab>
            </b-tabs>
          </b-col>
          <b-col v-if="keywords.length > 0" md="4">
            <b-card bg-variant="light">
              <div v-if="spatialExtent.length > 0" id="locator-map" />
              <MetadataSidebar
                :summaries="summaries"
                :keywords="keywords"
                :license="license"
                :temporalExtent="temporalExtent"
                :providers="providers"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import common from './common'

import Migrate from '@radiantearth/stac-migrate'

// ToDo 3.0: Import tabs lazily
import LinkTab from './LinkTab.vue'
import AssetTab from './AssetTab.vue'
import NavBar from './NavBar.vue'

const ITEMS_PER_PAGE = 50

function trim(path) {
  return path.replace(/\/?api\/v\d/, '')
}

export default {
  ...common,
  name: 'Catalog',
  props: {
    ancestors: {
      type: Array,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  components: {
    LinkTab,
    AssetTab,
    NavBar,
    MetadataSidebar: () => import(/* webpackChunkName: "metadata-sidebar" */ './MetadataSidebar.vue')
  },
  data() {
    return {
      childFields: [
        {
          key: 'link',
          label: 'Title',
          sortable: true
        },
        {
          key: 'keywords',
          label: 'Keywords',
          sortable: false
        },
        {
          key: 'constellation',
          label: 'Constellation',
          sortable: false
        }
      ],
      currentChildPage: 1,
      childrenPerPage: ITEMS_PER_PAGE, // also applies to collections
      locatorMap: null,
      tabIndex: 0,
      tabsChanged: false
    }
  },
  computed: {
    ...common.computed,
    ...mapGetters(['getEntity']),
    _entity() {
      let object = this.getEntity(this.url)
      if (object instanceof Error) {
        return object
      }
      // Clone to avoid changing the vuex store, remove once migration is done directly in vuex
      let cloned = JSON.parse(JSON.stringify(object))
      return Migrate.catalog(cloned)
    },
    _description() {
      return this.catalog.description
    },
    _license() {
      return this.catalog.license || (this.rootCatalog && this.rootCatalog.license)
    },
    _title() {
      return this.catalog.title
    },
    catalog() {
      return this.entity
    },
    childCount() {
      return this.children.length
    },
    children() {
      return this.links
        .filter((x) => x.rel === 'collection')
        .map((child) => {
          return {
            path: child.href,
            to: '/browse' + trim(child.href),
            // child.id is a workaround for https://earthengine-stac.storage.googleapis.com/catalog/catalog.json
            title: child.title || child.id,
            name: child.id,
            keywords: child.keywords,
            constellation: child.constellation,
            url: child.href
          }
        })
        .sort((a, b) => a.title?.localeCompare(b.title))
    }
  },
  watch: {
    ...common.watch,
    currentChildPage(to, from) {
      if (to !== from) {
        this.updateState({
          cp: to
        })
      }
    }
  },
  methods: {
    ...common.methods,
    ...mapActions(['load']),
    initialize() {
      this.syncWithQueryState()
    },
    selectTab(tabIndex) {
      if (typeof tabIndex !== 'number') {
        tabIndex = parseInt(tabIndex, 10)
        if (isNaN(tabIndex)) {
          return
        }
      }
      if (this.tabsChanged && this.tabIndex !== tabIndex) {
        this.updateState({
          t: tabIndex
        })
      }
      this.$nextTick(() => {
        this.tabIndex = tabIndex
      })
    },
    activateTab() {
      this.tabsChanged = true
    },
    syncWithQueryState() {
      this.selectTab(this.$route.query.t)
      this.currentChildPage = Number(this.$route.query.cp) || 1
      this.currentItemPage = Number(this.$route.query.ip) || 1

      // If we have external items, the b-table needs to "stay" on page 1 as
      // the items list only contains the number of items we want to show.
      this.currentItemListPage = this.hasExternalItems ? 1 : this.currentItemPage
    }
  }
}
</script>

<style src="./base.css"></style>

<style scoped lang="css">
h3 {
  margin-top: 25px;
  margin-bottom: 25px;
}

th h3 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  text-transform: uppercase;
  color: #555;
  font-weight: normal;
}

.table th {
  border-top: none;
  border-bottom: 1px solid #dee2e6;
}

td.provider {
  border: none;
  padding: 0 15px;
}

td.provider .description {
  padding-left: 5px;
  font-style: italic;
}

.leaflet-container {
  background-color: #262626;
}

#locator-map {
  height: 200px;
  width: 100%;
  margin-bottom: 10px;
}
</style>
