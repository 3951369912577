import { render, staticRenderFns } from "./Catalog.vue?vue&type=template&id=1c00c133&scoped=true"
import script from "./Catalog.vue?vue&type=script&lang=js"
export * from "./Catalog.vue?vue&type=script&lang=js"
import style0 from "./base.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./Catalog.vue?vue&type=style&index=1&id=1c00c133&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c00c133",
  null
  
)

export default component.exports